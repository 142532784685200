import netlifyIdentity from 'netlify-identity-widget';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { AuthenticationContext } from '../../contexts/Authentication';

export const Tasks = () => {
  const { token } = useContext(AuthenticationContext);
  const [isLoading, setLoading] = useState(true);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    if (!token) return;
    fetch('/.netlify/functions/listTasks?ts='+Date.now(), {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(results => {
        setTasks(results);
        setLoading(false);
      });
  }, [token]);

  const signOut = useCallback(() => {
    netlifyIdentity.logout();
  }, []);

  if (!token) {
    return <Redirect to="/" />;
  }

  return (
    <div className="mx-auto">

      <div className="flex items-center justify-between">
        <Link
          to="/tasks/create"
          className="btn btn-blue mb-5"
        >
          Create new
        </Link>
        <Link
          to="/"
          onClick={signOut}
          className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
        >
          Sign Out
        </Link>
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
          <table className="w-full shadow-md border-collapse border-2 border-gray-500">
            <thead>
              <tr>
                <th className="border border-gray-400 px-4 py-2 text-gray-800">ID</th>
                <th className="border border-gray-400 px-4 py-2 text-gray-800">Task Name</th>
                <th className="border border-gray-400 px-4 py-2 text-gray-800">Link</th>
                <th className="border border-gray-400 px-4 py-2 text-gray-800">Video</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map(({ _id, name, resolved, videoId }) => (
                <tr key={_id}>
                  <td className="border border-gray-400 px-4 py-2">{_id}</td>
                  <td className="border border-gray-400 px-4 py-2">{name}</td>
                  <td className="border border-gray-400 px-4 py-2">
                    <a
                      className="text-blue-500 hover:text-blue-800"
                      href={getLink(_id)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {getLink(_id)}
                    </a>
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    {resolved ? (
                      <a
                        className="text-blue-500 hover:text-blue-800"
                        href={getVideoLink(videoId)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getVideoLink(videoId)}
                      </a>
                    ) : (
                      <>Not yet</>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </div>
  );
};

const getLink = (id) => `${process.env.REACT_APP_URL || process.env.URL}/submit/${id}`;

const getVideoLink = (videoId) => `https://vimeo.com/${videoId}`;