import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SubmitVideo } from '../SubmitVideo/SubmitVideo';

export const Submit = () => {
  const { id } = useParams();
  const [task, setTask] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`/.netlify/functions/checkUrl?id=${id}`)
      .then(response => {
        if (response.status >= 400) {
          return '';
        }
        return response.json();
      })
      .then(result => {
        if (result) {
          setTask(result);
        }
        setLoading(false);
      });
  }, [id]);

  if (isLoading) {
    return 'Loading...';
  }

  if (!task) {
    return 'This link is invalid...';
  }

  if (task.resolved) {
    return 'This link is already resolved.';
  }

  return <SubmitVideo task={task} />;
};

