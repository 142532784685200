import React from 'react';

export const SubmitSuccess = () => {
  return (
    <div className="max-w-lg md:border-2 border-gray-500 rounded overflow-hidden md:shadow-lg mx-auto">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">
          Successfully uploaded the video!
        </div>
        <p className="text-gray-700 text-base">
          You can close this page.
        </p>
      </div>
    </div>
  );
};
