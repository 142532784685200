import netlifyIdentity from 'netlify-identity-widget';
import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthenticationContext } from '../../contexts/Authentication';

export const Home = () => {
  const { token } = useContext(AuthenticationContext);

  if (token) {
    netlifyIdentity.close();
    return <Redirect to="/tasks" />;
  }

  return (
    <div>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        type="button"
        onClick={() => netlifyIdentity.open()}
      >
        Sign In
      </button>
    </div>
  );
};
