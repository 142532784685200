import netlifyIdentity from 'netlify-identity-widget';
import React, { createContext, useState, useEffect } from "react";

export const AuthenticationContext = createContext();

export const Authentication = ({ children }) => {
  const [context, setContext] = useState({ token: null });
  useEffect(() => {
    function handleLogin(user) {
      if (user) {
        setContext({ token: user.token.access_token });
      } else {
        setContext({ token: null });
      }
    }
    function handleLogout() {
      setContext({ token: null });
    }
    netlifyIdentity.on('login', handleLogin);
    netlifyIdentity.on('logout', handleLogout);
    netlifyIdentity.init();
  }, []);

  return (
    <AuthenticationContext.Provider value={context}>
      {children}
    </AuthenticationContext.Provider>
  );
};
