import React, { useCallback, useContext, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { AuthenticationContext } from '../../contexts/Authentication';

export const CreateTask = () => {
  const { token } = useContext(AuthenticationContext);
  const [taskName, setTaskName] = useState('');
  const [created, setCreated] = useState(false);

  const createTask = useCallback(() => {
    if (!token) return;
    fetch('/.netlify/functions/createTask', {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name: taskName }),
    })
      .then(response => response.json())
      .then(results => {
        console.log(results);
        setCreated(true);
      });
  }, [taskName, token]);

  if (created) {
    return <Redirect to="/tasks" />;
  }

  return (
    <div className="w-full max-w-md mx-auto">
      <form className="bg-white shadow-md rounded border-2 border-gray-500 px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="taskName">
            Task Name
          </label>
          <input
            id="taskName"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Task name"
            onChange={({ target }) => setTaskName(target.value)}
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            onClick={createTask}
            className={`btn btn-blue ${!taskName && 'opacity-50 cursor-not-allowed'}`}
            type="button"
            disabled={!taskName}
          >
            Create
          </button>
          <Link
            to="/tasks"
            className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
          >
            Back to the list
          </Link>
        </div>
      </form>
    </div>
  );
};
