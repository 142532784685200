import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CreateTask } from '../CreateTask/CreateTask';
import { Home } from '../Home/Home';
import { Submit } from '../Submit/Submit';
import { SubmitSuccess } from '../SubmitSuccess/SubmitSuccess';
import { Tasks } from '../Tasks/Tasks';

export const Router = () => {
  return (
    <div className="container mx-auto md:py-10">
      <BrowserRouter>
        <Switch>
          <Route path="/submit/success">
            <SubmitSuccess />
          </Route>
          <Route path="/submit/:id">
            <Submit />
          </Route>
          <Route path="/tasks/create">
            <CreateTask />
          </Route>
          <Route path="/tasks">
            <Tasks />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
};
