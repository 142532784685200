import React, { useCallback, useEffect, useRef, useState } from 'react';
import tus from 'tus-js-client';
import { Redirect } from 'react-router-dom';

const SIZE_LIMIT = 150 * 1000 * 1000;

export const SubmitVideo = ({ task }) => {
  const player = useRef(null);
  const [file, setFile] = useState(null);
  const [duration, setDuration] = useState(null);
  const [dimensions, setDimensions] = useState(null);
  const getFileSize = useCallback(() => file ? (file.size / 1000 / 1000).toFixed(4) : 'unknown', [file]);
  const getDuration = useCallback(() => duration ? duration : 'unknown', [duration]);
  const [progress, setProgress] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);

  const handleFileSelect = useCallback((file) => {
    const isValidSize = file && file.size < SIZE_LIMIT; // 150MB
    if (file && isValidSize) {
      setFile(file);
      player.current.src = URL.createObjectURL(file);
      player.current.load();
    } else {
      if (file) {
        alert(`Sorry, provided recording is to big. The file is ${(file.size / 1000 / 1000).toFixed(2)}MB, whereas the limit is 100MB. Please, upload smaller recording.`);
      }
      setFile(null);
      player.current.src = null;
    }
  }, []);

  const uploadFile = useCallback(() => {
    if (file && file.size >= SIZE_LIMIT) {
      alert('Invalid file. Choose another.');
    }
    setProgress(0);
    fetch('/.netlify/functions/getUploadUrl', {
      method: 'POST',
      body: JSON.stringify({
        id: task.id,
        size: file.size,
      }),
    }).then(response => response.json()).then(results => {
      if (results && results.uploadUrl) {
        const upload = new tus.Upload(file, {
          uploadUrl: results.uploadUrl,
          retryDelays: [0, 3000, 5000, 10000, 20000],
          onError: function (error) {
            alert('An error occured.');
            console.log("Failed because: " + error)
          },
          onProgress: function (bytesUploaded, bytesTotal) {
            var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
            setProgress(percentage + "%");
          },
          onSuccess: function () {
            fetch('/.netlify/functions/resolveVideo?id='+task.id, {
              method: 'POST',
            }).then(() => {
              setIsUploaded(true);
            });
          }
        })

        // Start the upload
        upload.start()
      } else {
        alert('Something went wrong.');
      }
    });
    // redirect to success
  }, [file, task.id]);

  useEffect(() => {
    if (player.current) {
      const preview = player.current;
      const onLoaded = () => {
        setDuration(preview.duration);
        setDimensions(`${preview.videoWidth}x${preview.videoHeight}`)
      };
      preview.addEventListener('loadedmetadata', onLoaded, false);
      return () => preview.removeEventListener('loadedmetadata', onLoaded);
    }
  }, []);

  if (isUploaded) {
    return <Redirect to="/submit/success" />;
  }

  return (
    <div className="max-w-lg md:border-2 border-gray-500 rounded overflow-hidden md:shadow-lg mx-auto">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">
          Submit video for <span className="underline">{task.name}</span>
        </div>
        <p className="text-gray-700 text-base">
          Note: iOS users should use native camera app and choose the file from the recordings, otherwise quality will be very bad.<br />
        </p>
        <p className="font-bold text-red-700">Also, there is a maximum size limit of about 100MB.</p>
        <input
          type="file"
          onChange={({ target }) => {
            const file = target.files[0];
            handleFileSelect(file);
          }}
          className="mt-5"
          accept="video/*"
        />
      </div>
      <div className={!file ? 'hidden' : ''}>
        <video className="w-full" controls ref={player} controlsList="nodownload"></video>
        <p className="px-6 text-gray-700 text-base pt-4">
          {/* <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mb-2 mr-2">Name: {file && file.name}</span> */}
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mb-2 mr-2">Size: {getFileSize()}MB</span>
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mb-2 mr-2">Length: {getDuration()}s</span>
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mb-2 ">Resolution: {dimensions}</span>
        </p>
        <div className="px-6 py-4 flex items-center justify-between">
          {progress !== null ? (
            <div className="shadow w-full rounded-full bg-gray-500">
              <div className="bg-teal-700 rounded-full text-xs leading-none py-1 text-center text-white text-lg" style={{ width: progress }}>{progress}</div>
            </div>
          ) : (
            <>
              <span>Are you sure you want to upload this video?</span>
              <button
                className="btn btn-blue ml-4"
                onClick={uploadFile}
              >
                Upload
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
