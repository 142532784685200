import React from 'react';
import { Router } from './components/Router/Router';
import { Authentication } from './contexts/Authentication';

function App() {
  return (
    <Authentication>
      <Router />
    </Authentication>
  );
}

export default App;
